export const DownloadQrCode = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g transform="translate(3 0)">
        <path
          d="M0 6V4H6V6H0ZM0 14V12H6V14H0ZM0 18V14H2V16H4V14H6V18H0ZM0 0H6V4H4V2H2V4H0V0Z"
          fill="#4D2A6F"
        />
        <path
          d="M14 18L18 14L16.6 12.6L15 14.2V10H13V14.2L11.4 12.6L10 14L14 18Z"
          fill="#4D2A6F"
        />
        <path d="M8 8H10V10H8V8Z" fill="#4D2A6F" />
        <path d="M6 10H8V12H6V10Z" fill="#4D2A6F" />
        <path d="M4 8H6V10H4V8Z" fill="#4D2A6F" />
        <path d="M2 8H4V10H2V8Z" fill="#4D2A6F" />
        <path d="M0 10H2V12H0V10Z" fill="#4D2A6F" />
        <path d="M6 6H8V8H6V6Z" fill="#4D2A6F" />
        <path d="M8 3H10V5H8V3Z" fill="#4D2A6F" />
        <path d="M8 2H10V6H8V2Z" fill="#4D2A6F" />
        <path d="M10 0H18V6H12V2H13H14V4H16V2H14H13H10V0Z" fill="#4D2A6F" />
        <path d="M6 0H8V2H6V4V0Z" fill="#4D2A6F" />
        <path d="M0 6H2V8H0V6Z" fill="#4D2A6F" />
        <path d="M10 6H12V8H10V6Z" fill="#4D2A6F" />
      </g>
    </svg>
  );
};
