import styled from '@emotion/styled';
import { brandColors } from '../constants';

export const Fieldset = styled.fieldset`
  display: flex;
  padding: 0;
  flex-basis: auto;
  align-self: start;

  border-radius: 4px;
  border: 1px solid ${brandColors.laasPurple20};

  & > div {
    border-right: 1px solid ${brandColors.laasPurple20};

    &:last-of-type {
      border-right: none;
    }
  }
`;

export const FilterButtonGroup = (props: {
  children: React.ReactNode;
  className?: string;
}) => <Fieldset className={props.className}>{props.children}</Fieldset>;
