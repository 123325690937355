import { brandColors } from '../constants';

export const PositiveChangeIcon = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill={brandColors.laasPurple}
    {...props}
  >
    <path
      d="M11.2349 4.56667L12.1949 5.52667L8.94162 8.78L6.74828 6.58667C6.48828 6.32667 6.06828 6.32667 5.80828 6.58667L1.80828 10.5933C1.54828 10.8533 1.54828 11.2733 1.80828 11.5333C2.06828 11.7933 2.48828 11.7933 2.74828 11.5333L6.27495 8L8.46828 10.1933C8.72828 10.4533 9.14828 10.4533 9.40828 10.1933L13.1349 6.47333L14.0949 7.43333C14.3016 7.64 14.6616 7.49333 14.6616 7.2V4.33333C14.6683 4.14667 14.5216 4 14.3349 4H11.4749C11.1749 4 11.0283 4.36 11.2349 4.56667Z"
      fill="#4D2A6F"
    />
  </svg>
);

export const NegativeChangeIcon = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    fill={brandColors.laasPurple}
    {...props}
  >
    <path
      d="M11.2349 11.4334L12.1949 10.4734L8.94161 7.22006L6.74828 9.4134C6.48828 9.6734 6.06828 9.6734 5.80828 9.4134L1.80828 5.40673C1.54828 5.14673 1.54828 4.72673 1.80828 4.46673C2.06828 4.20673 2.48828 4.20673 2.74828 4.46673L6.27495 8.00006L8.46828 5.80673C8.72828 5.54673 9.14828 5.54673 9.40828 5.80673L13.1349 9.52673L14.0949 8.56673C14.3016 8.36006 14.6616 8.50673 14.6616 8.80006V11.6601C14.6616 11.8467 14.5149 11.9934 14.3283 11.9934H11.4683C11.1749 12.0001 11.0283 11.6401 11.2349 11.4334Z"
      fill="#4D2A6F"
    />
  </svg>
);

export const NoChangeIcon = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={brandColors.laasPurple}
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_10060_32488)">
      <path
        d="M12.6654 8.66659H3.33203V7.33325H12.6654V8.66659Z"
        fill="#4D2A6F"
      />
    </g>
    <defs>
      <clipPath id="clip0_10060_32488">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
