export const LinkCopy = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8923_10299)">
      <path d="M4 1H16V3H4V17H2V3C2 1.9 2.9 1 4 1Z" fill="#4D2A6F" />
      <path
        d="M11.25 17H13.05V15.8H11.25C10.875 15.8 10.5562 15.625 10.2937 15.275C10.0312 14.925 9.9 14.5 9.9 14C9.9 13.5 10.0312 13.075 10.2937 12.725C10.5562 12.375 10.875 12.2 11.25 12.2H13.05V11H11.25C10.6275 11 10.0969 11.2924 9.65835 11.8772C9.21945 12.4624 9 13.17 9 14C9 14.83 9.21945 15.5374 9.65835 16.1222C10.0969 16.7074 10.6275 17 11.25 17Z"
        fill="#4D2A6F"
      />
      <path d="M11.7 13.4V14.6H15.3V13.4H11.7Z" fill="#4D2A6F" />
      <path
        d="M13.95 15.8V17H15.75C16.3725 17 16.9032 16.7074 17.3421 16.1222C17.7807 15.5374 18 14.83 18 14C18 13.17 17.7807 12.4624 17.3421 11.8772C16.9032 11.2924 16.3725 11 15.75 11H13.95V12.2H15.75C16.125 12.2 16.4437 12.375 16.7063 12.725C16.9688 13.075 17.1 13.5 17.1 14C17.1 14.5 16.9688 14.925 16.7063 15.275C16.4437 15.625 16.125 15.8 15.75 15.8H13.95Z"
        fill="#4D2A6F"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 5H19C20.1 5 21 5.9 21 7V21C21 22.1 20.1 23 19 23H8C6.9 23 6 22.1 6 21V7C6 5.9 6.9 5 8 5ZM8 21H19V7H8V21Z"
        fill="#4D2A6F"
      />
    </g>
    <defs>
      <clipPath id="clip0_8923_10299">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
