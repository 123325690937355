import { SVGProps } from 'react';

export const GroupDistributionSize0to25 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.85384 7.23893C9.19007 7.23893 10.2733 6.15571 10.2733 4.81948C10.2733 3.48325 9.19007 2.40002 7.85384 2.40002C6.51761 2.40002 5.43439 3.48325 5.43439 4.81948C5.43439 6.15571 6.51761 7.23893 7.85384 7.23893Z"
      fill="black"
      fillOpacity="0.7"
    />
    <path
      d="M7.98371 7.72714C7.27684 7.6986 6.57151 7.81336 5.91014 8.06451C5.24878 8.31566 4.64506 8.69801 4.13532 9.18856L4 9.34011V12.7663C4.00211 12.8779 4.02619 12.988 4.07086 13.0903C4.11553 13.1926 4.17991 13.2851 4.26033 13.3625C4.34076 13.4399 4.43564 13.5007 4.53957 13.5414C4.64349 13.5822 4.75442 13.602 4.86602 13.5999H11.0852C11.1968 13.602 11.3077 13.5822 11.4116 13.5414C11.5155 13.5007 11.6104 13.4399 11.6908 13.3625C11.7713 13.2851 11.8357 13.1926 11.8803 13.0903C11.925 12.988 11.9491 12.8779 11.9512 12.7663V9.35094L11.8213 9.18856C11.3148 8.6965 10.7132 8.31307 10.0533 8.06178C9.39343 7.81048 8.68917 7.69661 7.98371 7.72714Z"
      fill="black"
      fillOpacity="0.7"
    />
  </svg>
);

export const GroupDistributionSize26to50 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.32188 7.26446C8.9873 7.01331 9.69697 6.89855 10.4082 6.92709C11.118 6.89656 11.8266 7.01043 12.4905 7.26173C13.1544 7.51303 13.7597 7.89645 14.2693 8.38851L14.4 8.55089V11.9663C14.3979 12.0779 14.3737 12.188 14.3287 12.2903C14.2838 12.3926 14.219 12.485 14.1381 12.5624C14.0572 12.6399 13.9617 12.7007 13.8571 12.7414C13.7526 12.7821 13.641 12.802 13.5287 12.7998H10.3512V9.87026L10.0147 9.44963L9.97876 9.41473C9.39417 8.84676 8.69974 8.40417 7.93804 8.1141C7.68389 8.01732 7.42404 7.9382 7.16034 7.87703C7.51945 7.62611 7.90953 7.4201 8.32188 7.26446Z"
      fill="black"
      fillOpacity="0.7"
    />
    <path
      d="M4.31292 8.63091C4.04019 8.68715 3.77171 8.76515 3.51012 8.86449C2.84876 9.11564 2.24504 9.49799 1.73529 9.98854L1.59998 10.1401V13.5663C1.60209 13.6779 1.62617 13.788 1.67083 13.8903C1.7155 13.9926 1.77989 14.0851 1.86031 14.1625C1.94073 14.2399 2.03562 14.3007 2.13954 14.3414C2.24347 14.3821 2.3544 14.402 2.466 14.3998H8.68513C8.79673 14.402 8.90767 14.3821 9.01159 14.3414C9.11552 14.3007 9.2104 14.2399 9.29082 14.1625C9.37124 14.0851 9.43563 13.9926 9.4803 13.8903C9.52497 13.788 9.54904 13.6779 9.55116 13.5663V10.1509L9.42125 9.98854C8.9148 9.49648 8.31319 9.11305 7.6533 8.86176C7.33802 8.74169 7.01261 8.653 6.68178 8.59644C6.30337 8.7527 5.88865 8.83891 5.4538 8.83891C5.05208 8.83891 4.66753 8.76533 4.31292 8.63091Z"
      fill="black"
      fillOpacity="0.7"
    />
    <path
      d="M12.7137 4.01943C12.7137 5.35566 11.6239 6.43888 10.2794 6.43888C9.66133 6.43888 9.09703 6.20992 8.66771 5.83267C8.67232 5.76219 8.67466 5.69108 8.67466 5.61943C8.67466 4.81774 8.38164 4.0845 7.89685 3.52095C8.12804 2.42387 9.10693 1.59998 10.2794 1.59998C11.6239 1.59998 12.7137 2.6832 12.7137 4.01943Z"
      fill="black"
      fillOpacity="0.7"
    />
    <path
      d="M5.45382 8.03892C6.79005 8.03892 7.87327 6.95569 7.87327 5.61947C7.87327 4.28324 6.79005 3.20001 5.45382 3.20001C4.11759 3.20001 3.03436 4.28324 3.03436 5.61947C3.03436 6.95569 4.11759 8.03892 5.45382 8.03892Z"
      fill="black"
      fillOpacity="0.7"
    />
  </svg>
);

export const GroupDistributionSize51to75 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.85384 8.03892C9.19007 8.03892 10.2733 6.95569 10.2733 5.61947C10.2733 4.28324 9.19007 3.20001 7.85384 3.20001C6.51761 3.20001 5.43439 4.28324 5.43439 5.61947C5.43439 6.95569 6.51761 8.03892 7.85384 8.03892Z"
      fill="black"
      fillOpacity="0.7"
    />
    <path
      d="M14.3129 4.01943C14.3129 5.35566 13.223 6.43888 11.8786 6.43888C11.5711 6.43888 11.277 6.38224 11.0062 6.27888C11.0505 6.06602 11.0738 5.84544 11.0738 5.61943C11.0738 4.52118 10.5239 3.55138 9.68439 2.97034C10.0776 2.1595 10.9123 1.59998 11.8786 1.59998C13.223 1.59998 14.3129 2.6832 14.3129 4.01943Z"
      fill="black"
      fillOpacity="0.7"
    />
    <path
      d="M4.7055 6.29553C4.44719 6.38831 4.16857 6.43888 3.87807 6.43888C2.53363 6.43888 1.44376 5.35566 1.44376 4.01943C1.44376 2.6832 2.53363 1.59998 3.87807 1.59998C4.83445 1.59998 5.66202 2.14814 6.06007 2.94559C5.20018 3.5236 4.63438 4.50544 4.63438 5.61943C4.63438 5.85134 4.6589 6.07753 4.7055 6.29553Z"
      fill="black"
      fillOpacity="0.7"
    />
    <path
      d="M7.98371 9.32675C7.27684 9.29821 6.57151 9.41297 5.91014 9.66412C5.24878 9.91527 4.64506 10.2976 4.13532 10.7882L4 10.9397V14.3659C4.00211 14.4775 4.02619 14.5876 4.07086 14.6899C4.11553 14.7922 4.17991 14.8847 4.26033 14.9621C4.34076 15.0395 4.43564 15.1003 4.53957 15.141C4.64349 15.1818 4.75442 15.2016 4.86602 15.1995H11.0852C11.1968 15.2016 11.3077 15.1818 11.4116 15.141C11.5155 15.1003 11.6104 15.0395 11.6908 14.9621C11.7713 14.8847 11.8357 14.7922 11.8803 14.6899C11.925 14.5876 11.9491 14.4775 11.9512 14.3659V10.9505L11.8213 10.7882C11.3148 10.2961 10.7132 9.91268 10.0533 9.66139C9.39343 9.41009 8.68917 9.29621 7.98371 9.32675Z"
      fill="black"
      fillOpacity="0.7"
    />
    <path
      d="M12.7516 12.7994H15.1281C15.2406 12.8017 15.3531 12.7822 15.4578 12.7415C15.5609 12.7001 15.6578 12.64 15.7375 12.5619C15.8188 12.4845 15.8844 12.3923 15.9281 12.29C15.9734 12.1876 15.9984 12.0775 16 11.9658V8.55092L15.8688 8.38842C15.3594 7.89623 14.7547 7.51264 14.0906 7.26186C13.4266 7.01029 12.7172 6.89623 12.0078 6.9267C11.8094 6.91889 11.6109 6.92201 11.4141 6.93608C11.1906 6.95248 10.9688 6.98295 10.75 7.0267C10.4094 7.72748 9.82188 8.28529 9.10001 8.58842C9.52188 8.6517 9.93751 8.76108 10.3375 8.9142C10.7531 9.07201 11.1484 9.27592 11.5172 9.51967C11.8234 9.72358 12.1125 9.95561 12.3781 10.2142L12.4141 10.2494L12.7516 10.6697V12.7994Z"
      fill="black"
      fillOpacity="0.7"
    />
    <path
      d="M4.00781 6.92672C4.31719 6.91344 4.625 6.92751 4.92969 6.96813C5.27813 7.72204 5.90938 8.31813 6.6875 8.62047C6.32656 8.6861 5.97188 8.78532 5.62656 8.91657C4.86406 9.20563 4.16875 9.64626 3.58125 10.2119L3.55938 10.233L3.2 10.6345V12.7994H0.871875C0.807813 12.8009 0.74375 12.7955 0.68125 12.7822C0.634375 12.7728 0.5875 12.7588 0.542188 12.7416C0.507812 12.7275 0.473438 12.7119 0.440625 12.6939C0.376563 12.658 0.315625 12.6142 0.2625 12.5619C0.18125 12.4845 0.115625 12.3924 0.071875 12.29C0.0265625 12.1877 0.0015625 12.0775 0 11.9658V8.54001L0.135937 8.38844C0.648438 7.89782 1.25625 7.51579 1.92188 7.26422C2.5875 7.01344 3.29688 6.8986 4.00781 6.92672Z"
      fill="black"
      fillOpacity="0.7"
    />
  </svg>
);

export const GroupDistributionSize76to100 = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_8848_382)">
      <path
        d="M7.98371 10.1272C7.27684 10.0986 6.57151 10.2134 5.91014 10.4645C5.24878 10.7157 4.64506 11.098 4.13532 11.5886L4 11.7401V15.1663C4.00211 15.2779 4.02619 15.388 4.07086 15.4903C4.11553 15.5926 4.17991 15.6851 4.26033 15.7625C4.34076 15.8399 4.43564 15.9007 4.53957 15.9415C4.64349 15.9822 4.75442 16.002 4.86602 15.9999H11.0852C11.1968 16.002 11.3077 15.9822 11.4116 15.9415C11.5155 15.9007 11.6104 15.8399 11.6908 15.7625C11.7713 15.6851 11.8357 15.5926 11.8803 15.4903C11.925 15.388 11.9491 15.2779 11.9512 15.1663V11.751L11.8213 11.5886C11.3148 11.0965 10.7132 10.7131 10.0533 10.4618C9.39343 10.2105 8.68917 10.0966 7.98371 10.1272Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M12.7512 14.3998H15.1287C15.241 14.4019 15.3526 14.3821 15.4571 14.3414C15.5617 14.3006 15.6572 14.2398 15.7381 14.1624C15.819 14.085 15.8838 13.9925 15.9287 13.8902C15.9737 13.7879 15.9979 13.6778 16 13.5662V10.1509L15.8693 9.98848C15.3598 9.49643 14.7544 9.113 14.0905 8.8617C13.4266 8.6104 12.718 8.49653 12.0082 8.52707C11.3135 8.49919 10.6203 8.60803 9.96839 8.84713C9.71142 9.07114 9.41855 9.25502 9.09955 9.389C9.52173 9.4526 9.93699 9.56135 10.338 9.71408C11.0997 10.0041 11.7942 10.4467 12.3788 11.0147L12.4147 11.0496L12.7512 11.4702V14.3998Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M4.00817 8.52707C4.53849 8.50425 5.06812 8.56205 5.57898 8.69755C5.8916 9.00973 6.2681 9.25796 6.68717 9.42096C6.32614 9.48683 5.97095 9.58561 5.62614 9.71655C4.8639 10.006 4.16809 10.4467 3.58059 11.012L3.55875 11.0331L3.2 11.4349V14.3998H0.871341C0.759055 14.4019 0.647442 14.3821 0.542878 14.3414C0.438315 14.3006 0.342848 14.2398 0.261932 14.1624C0.181017 14.085 0.116237 13.9925 0.0712941 13.8902C0.026351 13.7879 0.0021251 13.6778 0 13.5662V10.14L0.136147 9.98848C0.649024 9.49794 1.25645 9.11559 1.92187 8.86444C2.5873 8.61329 3.29696 8.49853 4.00817 8.52707Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M7.85384 8.83891C9.19007 8.83891 10.2733 7.75568 10.2733 6.41945C10.2733 5.08323 9.19007 4 7.85384 4C6.51761 4 5.43439 5.08323 5.43439 6.41945C5.43439 7.75568 6.51761 8.83891 7.85384 8.83891Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M10.7742 7.77644C10.9661 7.36408 11.0733 6.90429 11.0733 6.41947C11.0733 5.47994 10.6708 4.6344 10.0289 4.04586C10.4754 3.52809 11.1382 3.20001 11.878 3.20001C13.2225 3.20001 14.3124 4.28324 14.3124 5.61947C14.3124 6.95569 13.2225 8.03892 11.878 8.03892C11.4807 8.03892 11.1055 7.94428 10.7742 7.77644Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M4.94262 7.79591C4.62105 7.95158 4.25982 8.03892 3.87807 8.03892C2.53363 8.03892 1.44376 6.95569 1.44376 5.61947C1.44376 4.28324 2.53363 3.20001 3.87807 3.20001C4.60621 3.20001 5.25969 3.51776 5.70579 4.02135C5.04815 4.6108 4.63438 5.46681 4.63438 6.41947C4.63438 6.91193 4.74495 7.37857 4.94262 7.79591Z"
        fill="black"
        fillOpacity="0.7"
      />
      <path
        d="M9.37443 3.58101C8.92157 3.3379 8.40381 3.2 7.85384 3.2C7.31746 3.2 6.81171 3.33117 6.36693 3.56319C6.11573 3.26239 5.81058 3.00798 5.46648 2.81453C5.44537 2.68597 5.43439 2.55399 5.43439 2.41945C5.43439 1.08323 6.51761 0 7.85384 0C9.19007 0 10.2733 1.08323 10.2733 2.41945C10.2733 2.56479 10.2605 2.70714 10.2359 2.84542C9.90705 3.03869 9.61537 3.28828 9.37443 3.58101Z"
        fill="black"
        fillOpacity="0.7"
      />
    </g>
    <defs>
      <clipPath id="clip0_8848_382">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const GroupDistributionIcon = (
  props: SVGProps<SVGSVGElement> & { percentage: number },
) => {
  if (props.percentage <= 25) return <GroupDistributionSize0to25 {...props} />;
  if (props.percentage <= 50) return <GroupDistributionSize26to50 {...props} />;
  if (props.percentage <= 75) return <GroupDistributionSize51to75 {...props} />;
  return <GroupDistributionSize76to100 {...props} />;
};
